import LandingPage from "./LandingPage";

const Home = () => {
    return(
      <div>
        <LandingPage/>

      </div>
    );
  };
  
  export default Home;